.w-25px {
  width: 25px;
}
.border-top-1 {
    border-top: 1px solid var(--rfx-border);
}
.gray-color {
  color: var(--rfx-disabled-link);
}
.mt-n1 {
  margin-top: -0.25rem;
}
.bg-f9 {
  background-color: #F9F9F9;
}
.border-radius-3 {
  border-radius: 3px;
}
.cursor-pointer {
  cursor: pointer;
}