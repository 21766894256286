#criteria-proposal-response {
	.ng-value-icon {
        display: none;
}
.ng-value-label {
	background: white;
	font-weight: bold;
	padding: initial;
}
.ng-option-selected {
	font-weight: normal;
	accent-color: #00789C !important;
	background-color: white;
	input[type="checkbox"]{
            border-radius: unset;
}
} 
	.criteria-dropdown-footer {
		text-align: center;
		.btn {
        padding: 5px 10px;
		cursor: pointer;
		border-radius: unset;
		min-width: 80px;
	}
	.ng-dropdown-panel {
		.ng-dropdown-panel-items {
			.ng-option {
				&.ng-option-marked {
					background-color: white;
				}
			}
		}
	}
}
.btn-primary ,.btn-primary:hover {
	background-color: #00789C;
	color: white;
	border: none;
	margin-right: 5px;
}
.btn-secondary, .btn-secondary:hover {
	background-color: white;
	color: black;
	border: 1px solid#c6c6c6;
}
}