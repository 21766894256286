@media only screen and (max-width: 991px) {
    #sidebar {
        &.is-expanded {
            width: var(--rfx-sidebar-md-width);
        }
    }
    #main-content {
        &.is-expanded {
          margin-left: var(--rfx-sidebar-md-width);
        }
      }
}