.daterangepicker {
  &.ltr {
    .card-footer {
      background: none;
      display: flex;
      align-self: center;
      border: none;
      span {
        display: none !important;
      }
      &:has(button[title='Clear']) {
        padding-top: 2rem;
      }
      button[title='Clear'] {
        position: absolute;
        bottom: 90px;
        font-size: 14px;
        color: var(--rfx-danger);
        font-family: 'Roboto Bold';
        text-align: left;
        width: 80%;
        background-color: transparent;
        border: none;
        visibility: hidden;
        &:after {
          content: 'Changing the date range will affect all assigned team members';
          visibility: visible;
          display: block;
          position: absolute;
        }
      }
      .btn {
        width: 125px;
        height: 37px;
        border-radius: 3px;
        border: 1px 0px 0px 0px;
        opacity: 0px;
        background-color: #00789c;
      }
      .btn-secondary {
        background-color: #fff;
        margin-left: 7px;
        margin-right: 0px !important;
        color: black;
      }
    }
    td {
      width: 32px !important;
      height: 32px !important;
    }
    td.active,
    td.active:hover {
      background-color: #97dde4 !important;
    }
    .calendar {
      position: sticky;
      &.left {
        border-right: none !important;
        padding: 0 10px 10px;
        clear: left !important;
      }
      &.right {
        display: none !important;
      }
    }
  }
}
.calendar-table {
  padding-right: 0px !important;
}

.date-range {
  position: relative;
}
