/* You can add global styles to this file, and also import other style files */
:root,:host {
  --rfx-default: #000;
  --rfx-white: #fff;
  --rfx-theme: #082b49;
  --rfx-theme-hover: #001426;
  --rfx-background: #eaeaea;
  --rfx-border: #c6c6c6;
  --rfx-primary: #00789c;
  --rfx-primary-hover: #007395;
  --rfx-primary-active: #006a8a;
  --rfx-secondary: #ffffff;
  --rfx-secondary-hover: #e5e5e5;
  --rfx-secondary-active: #d7d7d7;
  --rfx-danger: #dc3545;
  --rfx-danger-hover: #d2293a;
  --rfx-danger-active: #bd1e2d;
  --rfx-warning: #ffa14a;
  --rfx-success: #7db02d;
  --rfx-small-text: #333333;
  --rfx-link: #007ea2;
  --rfx-disabled-link: #6e6e6e;
  --rfx-sidebar-width: 268px;
  --rfx-sidebar-md-width: 200px;
  --rfx-sidebar-width-collapsed: 54px;
  --rfx-placeholder: #666666;
}

@import 'ngx-toastr/toastr';
@import '@fortawesome/fontawesome-free/css/all.css';
@import './assets/styles/typography.scss';
@import './assets/styles/overrides/bootstrap/root.scss';
@import './assets/styles/overrides/bootstrap/_card.scss';
@import './assets/styles/overrides/bootstrap/_button.scss';
@import './assets/styles/overrides/bootstrap/_inputs.scss';
@import './assets/styles/overrides/bootstrap/_progress.scss';
@import './assets/styles/overrides/bootstrap/_modal.scss';
@import './assets/styles/overrides/bootstrap/_alerts.scss';
@import './assets/styles/overrides/bootstrap/_datepicker.scss';
@import './assets/styles/overrides/bootstrap/_ngselect.scss';
@import './assets/styles/utilities.scss';


.ngx-toastr {
  width: 450px !important;
}

.toast-container .ngx-toastr {
  color: black;
  border-radius: 8px;
  padding: 1.25rem 15px 1.25rem 5rem;
  background-position: 18px 15px;
  background-size: 40px;
  box-shadow: 0 0 5px #999999;
}
.toast-title {
  padding-bottom: 0.75rem;
}

.toast-error {
  background-color: #ffffff;
  background-image: url('/assets/svg/x-circle.svg');
}

.toast-info {
  background-color: #ffffff;
  background-image: url('/assets/svg/info.png');
}

.toast-message {
  color: #212121;
  padding-right: 2rem;
}

.toast-close-button {
  color: #797979;
  text-decoration: none;
  cursor: pointer;
  font-weight: 100;
  font-size: 28px;
  right: 0.25rem;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
.toast-message a {
  color: #212121;
  text-decoration: underline;
  font-weight: 100;
  font-family: 'Roboto Regular';
  font-size: 14px;
  &:hover {
    color: #212121;
    text-decoration: underline;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,app-root {
  height: 100vh;
  position: relative;
  margin: 0;

  a:hover, a:focus{
    text-decoration: none;
    cursor: pointer;
  }
}
#main-content {
  transition: 0.4s;
  padding: 1.5rem 1.5rem 1rem 2.25rem;
  margin-left: var(--rfx-sidebar-width-collapsed);
  &.is-expanded {
    margin-left: var(--rfx-sidebar-width);
  }
}

/* Override Bootstrap's tooltip styles */
.tooltip-inner {
  background-color: #ffffff !important; /* Force white background */
  color: #000000 !important; /* Force black text */
  border: 1px solid #c6c6c6 !important; /* Dark border */
  padding: 8px !important; /* Ensure padding is applied */
  // max-width: 200px !important;          /* Prevent tooltip from being too wide */
  text-align: center !important; /* Center-align text */
  border-radius: 0%;
  box-shadow: 0px 4px 4px 0px #0000001a;
}

/* Tooltip arrow styling for all positions */
.tooltip .tooltip-arrow::before {
  content: ''; /* Ensure pseudo-element exists */
  border-top-color: #ffffff !important; /* white arrow for top */
  border-bottom-color: #ffffff !important; /* white arrow for bottom */
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  // box-shadow: 0px 4px 4px 0px #0000001A;
}

/* Specific adjustments for each tooltip placement to ensure correct arrow positioning */
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: transparent !important; /* No top border */
  border-bottom-color: white !important; /* white arrow pointing downwards */
  // box-shadow: 0px 4px 4px 0px #0000001A;
}

/* Specific override for dynamically generated tooltips */
body .tooltip.show {
  opacity: 1 !important;
  z-index: 9999999999; /* Ensure tooltip is visible */
}
hr.custom {
  border-top: 1px solid #d9d9d9;
  opacity: 1;
}
.bg-transparent {
  background-color: transparent;
}
.build-flow-add-action {
  border: 1px dashed #999999;
  display: flex;
  align-items: center;
  height: 58px;
  border-radius: 3px;
  padding-left: 1rem;
  &:hover {
    border: 1px dashed var(--rfx-link);
  }
}
app-icon {
  &.error {
    svg {
      fill: var(--rfx-danger);
      stroke: #fff;
    }
  }
  &.warning {
    svg {
      fill: var(--rfx-warning);
      stroke: #fff;
    }
  }
  &.info {
    svg {
      fill: var(--rfx-primary-hover);
      stroke: #fff;
    }
  }
}
.rfx-sidebar {
  .menu-items {
    .nav-item {
      .nav-link {
        &::before {
          background-image: url('/assets/svg/radio-unchecked.svg');
        }
        &.Error {
          &::before {
            background-image: url('/assets/svg/radio-error.svg');
          }
        }
        &.Completed {
          &::before {
            background-image: url('/assets/svg/radio-completed.svg');
          }
        }
        &.is-active {
          &::before {
            background-image: url('/assets/svg/radio-checked.svg');
          }
        }
      }
    }
  }
}
.rfx-default-modal {
  .btn-close {
    --bs-btn-close-bg: url('/assets/svg/x.svg')  !important;
  }
  .custom-header {
     .btn-close {
       --bs-btn-close-bg: url('/assets/svg/x-black.png') !important;
     }
  }
}
.custom-accordion {
  .accordion-item {
    margin-bottom: 1rem;
    border: 1px solid #c6c6c6;
    background: #f5f5f5;
    border-radius: 3px;
    color: var(--rfx-default);
    &.has-warning {
      background: #fffaf1;
      border-color: var(--rfx-warning);
    }
    &.has-error {
      background: #fff6f7;
      border-color: var(--rfx-danger);
    }
    &.section-item {
      background: #fafafa;
      &.has-warning {
        background: #fffaf1;
        border-color: var(--rfx-warning);
      }
      &.has-error {
        background: #fff6f7;
        border-color: var(--rfx-danger);
      }
    }
    &.criteria-item {
      background: #ffffff;
      &.has-warning {
        background: #fffaf1;
        border-color: var(--rfx-warning);
      }
      &.has-error {
        background: #fff6f7;
        border-color: var(--rfx-danger);
      }
    }
    .stage-date {
      padding-left: 1.5rem;
    }
    .hover-action-items {
      button {
        visibility: hidden;
      }
      &:hover {
        button {
          visibility: visible;
        }
      }
      &:active {
        button {
          border-color: transparent;
        }
      }
    }
    &.is-edit {
      border: 2px solid var(--rfx-link);
    }
    .build-flow-add-edit-form-container {
      border: 2px solid var(--rfx-link);
      border-radius: 3px;
      .build-flow-add-edit-form-wrapper {
        position: relative;
        .drag-icon-disable {
          position: absolute;
          left: 10px;
          top: 20px;
        }
      }
      &.is-edit {
        padding: 0 !important;
        border: none;
        .build-flow-add-edit-form-wrapper {
          padding: 0.5rem !important;
          .drag-icon-disable {
            left: -5px;
          }
        }
        hr.custom {
          width: 120%;
        }
      }
    }
    .accordion-button {
      background: transparent;
      padding: 0;
      width: auto;
      display: flex;
      position: initial;
      height: 37px;
      &:focus {
        box-shadow: none;
      }
      &::after {
        background-image: url('/assets/svg/plus-circle.svg');
      }
      &:not(.collapsed) {
        box-shadow: none;
        &::after {
          background-image: url('/assets/svg/minus-circle.svg');
        }
      }
    }
    .accordion-body {
      padding: 0 0.75rem 0.75rem 0.75rem;
    }
  }
}
.spin {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  position: static;
  width: auto;
  height: auto;
}
.seq-num {
  position: absolute;
  top: 66%;
  left: 12px;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}
.form-control:focus + .seq-num,
.form-control:not(:placeholder-shown) + .seq-num {
  opacity: 1;
  padding-right: 25px !important;
}
.input-container {
  padding-left: 35px !important;
}

.drag-icon {
  cursor: move;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drop-list-dragging .accordion-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.accordion-type-table {
  .accordion-item {
    border: 0;
    border-radius: 0px;
    border-bottom: 1px solid var(--rfx-border);
    color: var(--rfx-default);
  }
  .accordion-body {
    background: #f5f5f5;
    border-top: 1px solid var(--rfx-border);
  }
  .accordion-button {
    background: transparent;
    padding: 0;
    width: auto;
    display: flex;
    position: initial;
    height: 37px;
    &:focus {
      box-shadow: none;
    }
    &::after {
      background-image: url('/assets/svg/plus-circle.svg');
    }
    &:not(.collapsed) {
      box-shadow: none;
      &::after {
        background-image: url('/assets/svg/minus-circle.svg');
      }
    }
  }
}
.accordion-type-list {
  .accordion-button {
    background: transparent;
    padding: 0;
    width: auto;
    display: flex;
    position: initial;
    height: 37px;
    &:focus {
      box-shadow: none;
    }
    &::after {
      background-image: url('/assets/svg/chevron-down.svg');
    }
    &:not(.collapsed) {
      box-shadow: none;
      &::after {
        background-image: url('/assets/svg/chevron-down.svg');
        transform: rotate(180deg);
      }
    }
  }
}
.evalListAlter {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.accordion-table .accordion-button {
  background: none!important;
  color: #000!important;
  padding: 12px!important;
  border: none;
  &:focus {
    box-shadow: none;
  }
  &::after {
    background-image: url('/assets/svg/minus-circle.svg');
  }
  &:not(.collapsed) {
    box-shadow: none;
    &::after {
      background-image: url('/assets/svg/plus-circle.svg');
    }
  }
}
.accordion-table .accordion-item {
    border:none;
}
.evaluator-editor .editor-container__editor {
  width: 100% !important;
}
.evaluator-editor .ck-editor__editable{
  width: auto;
}
app-icon.no-selection svg {
  fill:#00789C;
  stroke: #fff;
}
.ngb-dp-weekday, .ngb-dp-week-number {
  line-height: 2rem;
  text-align: center;
  font-style: normal !important;
  color: black !important;
}
.ngb-dp-week{
  background: white!important;
  border: none!important;
}
.ngb-dp-months {
  display: flex;
  justify-content: center!important;
  background-color: white!important;
}  
 .custom-day {
  text-align: center;
  padding: 0.2rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.in-range {
  background-color: rgba(0, 123, 255, 0.2) !important;
  color: black !important;
}

.hovered {
  background-color: rgba(0, 123, 255, 0.5) !important;
}

.range-start, .range-end{
    background-color: #97dde4 !important;
    color: white !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    align-content: center;
}  

.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
  width: 2.2rem!important;
  height: 2.2rem!important;
}
.ngb-dp-header {
  background: white !important;
   .form-select{
    margin-right: 5px;
   }
}
.start-time{
  cursor: pointer;
}
.app-container{
  padding:0 40px !important;
}
/* Chrome, Safari, Edge */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

