@import './fonts/fonts.scss';
body,
rfx-app,
button,
.btn {
  font-family: 'Roboto Regular';
  font-size: 16px;
}
body {
  color: var(--rfx-default);
}
h1 {
  font-size: 24px;
  font-family: 'Poppins Bold';
  color: var(--rfx-default);
}
h2 {
  font-size: 20px;
  font-family: 'Poppins Bold';
  color: var(--rfx-default);
}
h3 {
  font-size: 16px;
  font-family: 'Poppins Bold';
  color: var(--rfx-default);
}
h4 {
  font-size: 16px;
  font-family: 'Roboto Bold';
  color: var(--rfx-default);
}
a {
  font-size: 16px;
  font-family: 'Roboto Bold';
  color: var(--rfx-link);
  text-decoration: none;
  &.link-theme {
    color: var(--rfx-theme);
  }
  &.disabled {
    color: var(--rfx-disabled-link);
    opacity: 0.65;
    pointer-events: none;
    cursor: not-allowed;
  }
  &.add {
    &::before {
      content: '+';
      margin-right: 0.25rem;
    }
  }
  &.normal-text {
    font-family: 'Roboto Regular';
    color: var(--rfx-default);
  }
  &:hover {
    text-decoration: underline;
  }
}
.strong {
  font-family: 'Roboto Bold';
}
small {
  font-size: 14px;
  font-family: 'Roboto Regular';
  color: var(--rfx-small-text);
}
.font-14 {
  font-size: 14px;
}
.fa {
  font-family: var(--fa-style-family, 'Font Awesome 6 Free');
  font-weight: var(--fa-style, 900);
}
.fa-classic,
.fas,
.fa-solid,
.far,
.fa-regular {
  font-family: 'Font Awesome 6 Free';
}

.fa-brands,
.fab {
  font-family: 'Font Awesome 6 Brands';
}
