.modal {
  .w-650 {
    max-width: 650px;
  }
  .modal-content {
    border-radius: 3px;
    .modal-header {
      .btn-close {
        opacity: 1;
        &.custom-close {
          margin-top: -70px;
          margin-right: 0px;
        }
      }
    }
  }
}
