.progress {
  background-color: #fff;
  border: 1px solid #969696;
  padding: 1px;
  border-radius: 100px;
  &[aria-valuenow='0'] {
    background-color: #dedede;
    border: 1px solid #dedede;
  }
  .progress-bar {
    background-color: #6e6e6e;
    border-radius: 100px;
  }
  &[aria-valuenow='100'] {
    .progress-bar {
      background-color: var(--rfx-primary);
    }
  }
}
