$poppins-font-path: '/assets/fonts/poppins' !default;
$font-fallbacks: Arial, sans-serif;

@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 500;
  src:
    url('#{$poppins-font-path}/Poppins-SemiBold.woff2') format('woff2'),
    url('#{$poppins-font-path}/Poppins-SemiBold.woff') format('woff'),
    url('#{$poppins-font-path}/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: 400;
  src:
    url('#{$poppins-font-path}/Poppins-Regular.woff2') format('woff2'),
    url('#{$poppins-font-path}/Poppins-Regular.woff') format('woff'),
    url('#{$poppins-font-path}/Poppins-Regular.ttf') format('truetype');
}
