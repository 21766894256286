.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-control {
  &.h-50 {
    height: 50px !important;
    border-radius: 4px;
    border: 1px solid #c6c6c6;
  }
}
.form-floating {
  .form-control,
  .form-select {
    height: 50px !important;
    min-height: 40px !important;
    border-radius: 4px;
    border: 1px solid #c6c6c6;
    text-transform: capitalize;
    & ~ label {
      color: #666666;
      padding: 0.75rem;
    }
    &.has-icon {
      padding-left: 2rem;
      & ~ label {
        padding-left: 2.35rem;
      }
    }
    &:focus ~ label,
    &:not(:placeholder-shown) ~ label,
    .form-control-plaintext ~ label,
    .form-select ~ label {
      color: #666666;
      font-size: 14px;
    }
    & ~ .input-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 100;
    }
  }
  .form-select {
    padding-bottom: 0;
    &:not(.has-value) ~ label {
      font-size: 16px;
      transform: none;
    }
    &:focus ~ label {
      font-size: 14px;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
  }
}

input[type=checkbox]
{
  -ms-transform: scale(1.4); /* IE */
  -moz-transform: scale(1.4); /* FF */
  -webkit-transform: scale(1.4); /* Safari and Chrome */
  -o-transform: scale(1.4); /* Opera */
  transform: scale(1.4);
  accent-color: var(--rfx-primary);
}